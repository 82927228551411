import React from 'react'
import Link from 'gatsby-link'
import Layout from "../layouts/layout.js"
import SLink from "../components/SLink";

const IndexPage = () => (
  <Layout hideFooter={true}>
    <div className="aboutWrapper">
      {/*<p>*/}
      {/*  Canal Swans is a website/wiki/blog maintained by <a href="http://mfowler.info">Max Fowler</a>.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  index contains writing and poetry.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  zines was imagined to become a small online store for ordering*/}
      {/*  physical copies of zines, inspired by <a href="http://www.finnoakes.com/new-products">finn oakes' zine store</a>,*/}
      {/*  but currently just contains one zine.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  wiki contains collections of notes, writing and links on various topics.*/}
      {/*  I wanted to collect research in a way that was more engaged than just*/}
      {/*  compulsively saving things to are.na channels, but was less polished*/}
      {/*  than one-time-published pieces of writing. a digital garden of sorts,*/}
      {/*  and a tribute to serendipity and special interests.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  misc is for anything that doesn't fit.*/}
      {/*</p>*/}
      <p>
          garden of fragments, writing, notes
      </p>
      <p>
          a tribute to serendipity, special interests and html
      </p>
      <p>
        maintained by <a href="https://mfowler.info">notplants</a>
      </p>
      {/*<p>*/}
      {/*  This is a blog for sharing writing, notes, sketches and other fragments.*/}
      {/*  <span style={{"margin-top": "10px", "display": "block"}}> Maintained by <a href="http://mfowler.info">Max Fowler</a>. </span>*/}
      {/*</p>*/}
      <div className="aboutLinksWrapper">
        <table className="aboutLinks">
            <a className="hlink" href="/rss.xml" style={{'margin-right': '20px'}}>rss</a>
            <SLink className="hlink" to='https://gaia.chat/mailman/cgi-bin/listinfo/canalswans'>newsletter</SLink>
            <SLink className="hlink" to='https://sunbeam.city/@notplants' style={{'margin-left': '20px'}}>fediverse</SLink>
        </table>
      </div>

      <a href="https://webring.xxiivv.com/canalswans" target="_blank" rel="noopener" style={{"margin": "auto", "max-width": "150px", "margin-top": "20px", "display": "block"}}>
        <img src="https://webring.xxiivv.com/icon.black.svg" alt="XXIIVV webring" style={{'max-width': "150px", "display": "block"}}/>
        <label style={{"max-width:": "150px", "display": "block", "color": "gray"}}>XXIIVV webring</label>
      </a>
    </div>
  </Layout>
)

export default IndexPage
